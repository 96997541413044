.particle-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: lightblue;
    
}

.particle {
    position: absolute;
    width: 5px; /* Adjust as needed */
    height: 5px; /* Adjust as needed */
    background-color: blue;
    border-radius: 50%;
    opacity: 0.8; /* Adjust opacity if desired */
}
