/* ... Previous CSS rules ... */

.main-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px 0;
    text-align: center;
  }
  
  .left-section {
    max-width: 50%;
    text-align: center;
  }
  
  .left-section h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .left-section p {
    font-size: 20px;
    line-height: 2;
    margin-bottom: 20px;
  }
  
  .try-on-button {
    background-color: #007bff; /* Primary blue color */
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }
  
  .try-on-button:hover {
    background-color: #0062cc; /* Darker blue on hover */
  }
  
  .right-section {
    max-width: 45%;
  }
  
  .screenshot-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
  }
  
  .screenshot {
    width: 300px;
    height: 200px;
    object-fit: cover;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
  }
  
  .screenshot:hover {
    transform: scale(1.05);
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
  }
  
  .download-buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
    padding: 20px;
  }
  
  .app-store-button,
  .google-play-button {
    display: inline-block;
    background-color: #0071e3; /* Example color for App Store button */
    color: white;
    text-decoration: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-weight: bold;
  }
  
  .google-play-button {
    background-color: #78c257; /* Example color for Google Play button */
  }
  
  /* Adjust the colors, padding, and other styles to match your design */
@keyframes blinker {
    50% {
        opacity: 0;
    }
}

.blinking-link {
    animation: blinker 1s linear infinite;
    color: #0094ff; /* Change the color as needed */
    font-weight: bold; /* Optional: makes the link text bold */
}