.feature-quadrants {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
  }
  
  .feature {
    text-align: center;
    padding: 20px;
    font-size: 18px;
    line-height: 1.5;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
  }
  
  .feature:hover {
    transform: scale(1.05);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  }
  
  .feature-icon {
    width: 100px;
    height: 100px;
    margin-bottom: 10px;
  }
  