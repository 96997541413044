.contact-container {
  padding: 20px;
  text-align: center;
  background-color: #f7f7f7;
  color: white;
}

.contact-header {
  text-align: center;
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #333;
}

.contact-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 1.2rem;
}

.contact-address, .contact-form {
  flex: 1;
  margin: 0 20px;
}

.contact-address h2, .contact-address h4 {
  text-align: center;
  margin: 10px 0;
}

.contact-form {
  text-align: center;
}

.input-group {
  margin-bottom: 15px;
  text-align: left;
}

.input-group label {
  display: block;
  font-size: 1.2rem;
  margin-bottom: 5px;
}

.input-group input, .input-group textarea {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
}

.input-group input[type="checkbox"] {
  width: auto;
  margin-right: 10px;
}

.input-group label[for="subscribe"] {
  display: flex;
  align-items: center;
}

button[type="submit"] {
  padding: 10px 20px;
  font-size: 1.2rem;
  background-color: #333;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

button[type="submit"]:hover {
  background-color: #555;
}
