.team-container {
    padding: 20px;
    text-align: center;
    background-color: lightblue;
  }
  
  .team-header {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #333;
  }
  
  .team-grid {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
  }
  
  .team-card {
    
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 300px;
    text-align: left;
    text-decoration-color: aliceblue;
  }
  
  .team-image {
    width: 100%;
    border-radius: 8px 8px 0 0;
  }
  
  .team-name {
    font-size: 1.5em;
    margin: 10px 0;
  }
  
  .team-title {
    font-size: 1.2em;
    color: darkblue;
  }
  
  .team-bio {
    font-size: 1em;
    color: black;
  }
  