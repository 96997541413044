.accredited-section {
    text-align: center;
    padding: 20px;
}

.accredited-section h1 {
    font-size: 2rem;
    margin-bottom: 20px;
}

.accredited-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.accredited-list img {
    max-width: 150px;
    height: auto;
}
